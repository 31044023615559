define(["handlebars"], function(Handlebars) { return Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, helperMissing=helpers.helperMissing, self=this, escapeExpression=this.escapeExpression, functionType="function";

function program1(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n		<tr>\n			<td class=\"\">";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.distance), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.distance), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " "
    + escapeExpression((helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.units), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.units), options)))
    + "</td>\n			<td class=\"\">";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.co_name), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.co_name), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</td>\n			<td class=\"\">\n				";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.address1)),stack1 == null || stack1 === false ? stack1 : stack1.length), {hash:{},inverse:self.noop,fn:self.program(2, program2, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n				";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.address2)),stack1 == null || stack1 === false ? stack1 : stack1.length), {hash:{},inverse:self.noop,fn:self.program(4, program4, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n				";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.address3)),stack1 == null || stack1 === false ? stack1 : stack1.length), {hash:{},inverse:self.noop,fn:self.program(6, program6, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n				\n				";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.city)),stack1 == null || stack1 === false ? stack1 : stack1.length), {hash:{},inverse:self.noop,fn:self.program(8, program8, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n				";
  stack1 = (helper = helpers.if_not_match || (depth0 && depth0.if_not_match),options={hash:{
    'compare': ("NULL")
  },inverse:self.noop,fn:self.program(10, program10, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.state_province), options) : helperMissing.call(depth0, "if_not_match", (depth0 && depth0.state_province), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n				";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.postal_code)),stack1 == null || stack1 === false ? stack1 : stack1.length), {hash:{},inverse:self.noop,fn:self.program(13, program13, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "					\n\n				";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.country)),stack1 == null || stack1 === false ? stack1 : stack1.length), {hash:{},inverse:self.noop,fn:self.program(15, program15, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "	\n\n				";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.phone_number)),stack1 == null || stack1 === false ? stack1 : stack1.length), {hash:{},inverse:self.noop,fn:self.program(17, program17, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "	\n\n				";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.directions_link)),stack1 == null || stack1 === false ? stack1 : stack1.length), {hash:{},inverse:self.noop,fn:self.program(19, program19, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "	\n			</td>\n		</tr>\n	";
  return buffer;
  }
function program2(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n					";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.address1), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.address1), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "<br>\n				";
  return buffer;
  }

function program4(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n					";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.address2), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.address2), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "<br>\n				";
  return buffer;
  }

function program6(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n					";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.address3), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.address3), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "<br>\n				";
  return buffer;
  }

function program8(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n					";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.city), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.city), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "<br>\n				";
  return buffer;
  }

function program10(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n					";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.state_province)),stack1 == null || stack1 === false ? stack1 : stack1.length), {hash:{},inverse:self.noop,fn:self.program(11, program11, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n				";
  return buffer;
  }
function program11(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n						";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.state_province), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.state_province), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "<br>\n					";
  return buffer;
  }

function program13(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n					";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.postal_code), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.postal_code), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "<br>\n				";
  return buffer;
  }

function program15(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n					 ";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.country), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.country), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "<br>\n				";
  return buffer;
  }

function program17(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n					";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.phone_number), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.phone_number), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "<br>\n				";
  return buffer;
  }

function program19(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n					<a href=\"";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.directions_link), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.directions_link), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" target=\"_blank\" rel=\"external\">Get Directions </a>\n				";
  return buffer;
  }

  buffer += "\n<p>There are ";
  if (helper = helpers.location_count) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.location_count); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " store(s) found on your current search.</p>\n\n<table class=\"\">\n	<tr>\n		<th class=\"\">Distance  </th>\n		<th class=\"\">Store Name & Information </th>\n		<th class=\"\">Address & Directions </th>\n	</tr>\n\n	";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.locations), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n</table>";
  return buffer;
  }); });